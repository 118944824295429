import React from 'react'
import styled from 'styled-components'

// Components
import Plaatjie from '@ubo/plaatjie'
import Crumbs from 'components/elements/Misc/Crumbs'
import ParseContent from 'components/shared/ParseContent'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const Section = styled.section`
  @media (max-width: 575px) {
    min-height: 380px;
  }
`

const BannerWrapper = styled.div`
  @media (min-width: 576px) {
    min-height: 539px;
    background: linear-gradient(
      90deg,
      rgba(99, 110, 255, 0.44) 0%,
      #636eff 48.37%
    );
  }

  @media (max-width: 575px) {
    background: linear-gradient(
      180deg,
      rgba(99, 110, 255, 0.44) 0%,
      #636eff 68.37%
    );
    padding-bottom: 3rem;
    min-height: 380px;

    & > div {
      height: 100%;
    }
  }
`

const Background = styled(Plaatjie)`
  @media (min-width: 576px) {
    height: 539px;
    bottom: 0;

    & > div {
      height: 539px;
    }
  }

  @media (max-width: 575px) {
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
    color: ${(props) => props.theme.color.light};
  }

  & a:hover {
    color: ${({ theme }) => theme.color.primary} !important;
  }
`

const BannerDefault = ({ fields, pageContext }: BannerProps) => (
  <Section className="position-relative mb-5">
    <Background image={fields.image} alt="" className="position-absolute" />
    <BannerWrapper className="position-relative">
      <div className="container h-100">
        <div
          className={`row justify-content-${fields.descriptionposition === 'left' ? 'start' : 'end'
            } h-100`}
        >
          <div className="col-lg-8">
            <div className="pt-5">
              <div className="my-5 py-5">
                <Crumbs pageContext={pageContext} className="mb-4" />
                <Content content={fields.description} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerWrapper>
  </Section>
)

export default BannerDefault
