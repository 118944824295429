import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import Crumbs from 'components/elements/Misc/Crumbs'

// Interface
import { BannerProps } from './BannerShell'

const Section = styled.section``

const BannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  @media (min-width: 992px) {
    height: 475px;
  }
`

const Thumbnail = styled.div`
  background-color: ${({ theme }) => theme.color.grey};

  @media (min-width: 1200px) {
    width: 375px;
    height: 375px;
  }

  @media (max-width: 1199px) {
    width: 220px;
    height: 220px;
  }

  @media (max-width: 991px) {
    width: 160px;
    height: 160px;
  }
`

const Rounded = styled(Plaatjie)`
  width: 70%;
  & img {
    object-fit: contain !important;
  }
  @media (min-width: 1200px) {
    height: 310px;
  }

  @media (max-width: 1199px) {
    height: 180px;
  }

  @media (max-width: 991px) {
    height: 110px;
  }
`

const Apple = styled(Plaatjie)`
  border-radius: 50px;

  @media (max-width: 575px) {
    width: 160px;
    height: 160px;
    border-radius: 12px;
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.color.light};
  }
`

const Row = styled.div`
  padding-top: 9rem;
`

const BannerPost = ({ fields, pageContext }: BannerProps) => (
  <Section className="position-relative">
    <BannerWrapper className="position-relative">
      <div className="container h-100">
        <Row className="row h-100 align-items-center">
          <div className="col-sm-4">
            {fields.image && (
              <>
                {fields.post?.imagetype === 'rounded' && (
                  <Thumbnail className="d-flex justify-content-center align-items-center rounded-circle">
                    <Rounded image={fields.image} alt="" />
                  </Thumbnail>
                )}
                {fields.post?.imagetype === 'apple' && (
                  <Apple image={fields.image} alt="" />
                )}
              </>
            )}
          </div>
          <div className="col-sm-8">
            <div className={`pt-4 ${fields.image ? 'ms-3' : ''}`}>
              <Crumbs pageContext={pageContext} className="mb-5" />
              <Content content={fields.description} className="mb-3" />
            </div>
          </div>
        </Row>
      </div>
    </BannerWrapper>
  </Section>
)

export default BannerPost
