import React from 'react'

// Components
import BannerDefault from 'components/flex/Banner/BannerDefault'
import BannerLanding from 'components/flex/Banner/BannerLanding'
import BannerDetail from 'components/flex/Banner/BannerDetail'
import BannerPost from 'components/flex/Banner/BannerPost'

export interface BannerProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
  pageContext?: any
}

interface BannersProps {
  [key: string]: any
}

const BannerShell = ({ fields, location = {}, pageContext }: BannerProps) => {
  const banners: BannersProps = {
    default: BannerDefault,
    landing: BannerLanding,
    detail: BannerDetail,
    post: BannerPost,
  }

  if (!fields.styletype || !banners[fields.styletype]) {
    return null
  }

  const Component = banners[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default BannerShell
