import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import Circle from 'components/elements/Vectors/Circle'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.primary};
`

const BannerWrapper = styled.div`
  @media (min-width: 576px) {
    height: 694px;
  }
`

const Image = styled(Plaatjie) <{ image: any }>`
  z-index: 2;
  @media (min-width: 992px) {
    width: 482px;
    height: 482px;
  }
  @media (max-width: 991px) {
    width: 332px;
    height: 332px;
  }
  @media (max-width: 767px) {
    width: 252px;
    height: 252px;
  }
  @media (max-width: 575px) {
    width: 222px;
    height: 222px;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & h1 {
    margin-bottom: 2rem;
    color: ${(props) => props.theme.color.light};
  }

  & p {
    margin-bottom: 2rem;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.big};
  }
`

const BannerLanding = ({ fields }: BannerProps) => (
  <Section className="position-relative mb-5 pb-lg-5">
    <BannerWrapper>
      <div className="container py-5 py-sm-0 h-100">
        <div className="row h-100 align-items-center">
          <div className="col-sm-6 mt-5 order-sm-2 d-flex justify-content-center justify-content-sm-start">
            <Image
              alt="banner"
              image={fields?.image}
              className="rounded-circle"
            />
          </div>
          <div className="col-sm-6 mt-5">
            <Content content={fields.description} className="pe-lg-3" />
          </div>
        </div>
      </div>
    </BannerWrapper>
    <Circle
      identifier="home-banner-circle"
      absolute
      width={283}
      height={283}
      top={20}
      right={-10}
    />
    <Circle
      identifier="home-small-banner-circle"
      absolute
      width={120}
      height={120}
      bottom={5}
      right={10}
      strokeWidth={20}
    />
  </Section>
)

export default BannerLanding
