import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import Circle from 'components/elements/Vectors/Circle'
import Crumbs from 'components/elements/Misc/Crumbs'

// Interface
import { BannerProps } from 'components/flex/Banner/BannerShell'

const Section = styled.section``

const BannerWrapper = styled.div`
  z-index: 2;

  @media (min-width: 576px) {
    min-height: 769px;
    background: linear-gradient(
      90deg,
      rgba(99, 110, 255, 0.44) 0%,
      #636eff 48.37%
    );
  }

  @media (max-width: 575px) {
    background: linear-gradient(
      180deg,
      rgba(99, 110, 255, 0.44) 0%,
      #636eff 68.37%
    );
  }
`

const Background = styled(Plaatjie)`
  @media (min-width: 576px) {
    height: 769px;

    & > div {
      height: 769px;
    }
  }
`

const Content = styled(ParseContent)`
  color: ${({ theme }) => theme.color.light};

  & h1,h2 {
    color: ${(props) => props.theme.color.light};
  }

  & p {
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`

const Col = styled.div`
  z-index: 2;
  padding-top: 10rem;
  padding-bottom: 2rem;
`

const Icon = styled(Plaatjie)`
  width: 155px;
  height: 155px;
`

const Highlight = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  padding: 0.5rem 0.5rem 0 0.5rem;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  width: 214px;
`

const Image = styled(Plaatjie)`
  width: 175px;
  height: 98px;
`

const Row = styled.div`
  position: relative;
  z-index: 2;
`

const BannerDetail = ({ fields, pageContext }: BannerProps) => (
  <Section className="position-relative mb-5 pb-lg-5">
    <Background image={fields.image} alt="" className="position-absolute" />
    <BannerWrapper className="position-relative d-flex align-items-end">
      <div className="container h-100">
        <Row className="row h-100 justify-content-start align-items-end">
          <Col className="col-xl-8 mb-5 mb-sm-0 mb-md-5 mt-5 mt-xl-0 order-xl-2">
            <div className="me-sm-5">
              <div className="d-flex flex-column flex-md-row">
                <Icon
                  image={fields.detail?.icon}
                  alt=""
                  className="mb-4 mb-sm-5 me-sm-5"
                />
                <div className="ms-md-3 mb-4 mb-md-0">
                  <Crumbs pageContext={pageContext} className="mb-4" />
                  <Content content={fields.detail?.title} />
                </div>
              </div>
              <Content content={fields.description} />
            </div>
          </Col>
          <div className="col-xl-4 d-flex flex-column align-items-end align-items-lg-start">
            <Highlight className="d-flex justify-content-center">
              <Image image={fields.detail?.highlight} alt="" />
            </Highlight>
          </div>
        </Row>
      </div>
      <Circle
        absolute
        bottom={3}
        right={-9}
        identifier="banner-service-circle"
        width={283}
        height={283}
      />
    </BannerWrapper>
  </Section>
)

export default BannerDetail
